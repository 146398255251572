import axios from "axios";
// import Router from "next/router";
// const googleMapClient = axios.create({
//   baseURL: "https://maps.googleapis.com/maps",
//   timeout: parseInt(process.env.API_TIMEOUT),
// });

const client = axios.create({
  baseURL: "https://nanonets.solusiaplikasi.id/api",
  //   timeout: parseInt(10000),
});

export const headerOptions = {
  "Content-Type": "multipart/form-data",
};

// const createHeader = (token, headerOptions) => {
//   if (token) {
//     return {
//       headers: {
//         Accept: "application/json",
//         Authorization: `${token}`,
//         ...headerOptions,
//       },
//     };
//   }
// };
const createHeaderNonAuthorization = (headerOptions) => {
  return {
    headers: {
      Accept: "application/json",
      ...headerOptions,
    },
  };
};

client.interceptors.response.use(undefined, async (err) => {
  const error = err.response;
  //   if (error && error.status === 401) {
  //     Router.push("/login");
  //   }
  throw err;
});

export { client, createHeaderNonAuthorization };
