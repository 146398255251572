import { client, createHeaderNonAuthorization, headerOptions } from "./index";

export const postPhotoAPI = (data) =>
  client.post("/ktp", data, createHeaderNonAuthorization(headerOptions));
export const postBpkbAPI = (data) =>
  client.post("/bpkb", data, createHeaderNonAuthorization(headerOptions));
export const postSimAPI = (data) =>
  client.post("/sim", data, createHeaderNonAuthorization(headerOptions));
export const postNpwpAPI = (data) =>
  client.post("/npwp", data, createHeaderNonAuthorization(headerOptions));
export const getPhotoAPI = (model = "KTP") =>
  client.get(`/ktp?model=${model}`, createHeaderNonAuthorization());
export const getDetailAPI = (id) =>
  client.get(`/ktp/${id}`, createHeaderNonAuthorization());
