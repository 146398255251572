import React, { useState, useEffect, useMemo } from "react";
// import Chart from "chart.js";

import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Button,
  Tabs,
  Tab,
  Typography,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { CameraAlt } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
// import DashboradView from "views/admin/Dashboard";

// core components
// import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/dashboard.js";

import { postPhotoAPI } from "../../api/upload";

const useStyles = makeStyles(componentStyles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function Ktp() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [picture, setPicture] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = "Demo KTP Extraction";
  }, []);

  const validationData = useMemo(() => {
    let newArr = data.sort((a, b) => {
      if (parseInt(a.score) > parseInt(b.score)) return -1;
      if (parseInt(a.score) < parseInt(b.score)) return 1;
      return 0;
    });

    let newData = newArr.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.label === thing.label)
    );
    return newData;
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const handleChangeIndex = (index) => {
  //     setValue(index);
  //   };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        920,
        920,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleLogo = async (val) => {
    setIsOpen(true);

    try {
      setPicture(val.target.files[0]);
      const resizeImage = await resizeFile(val.target.files[0]);
      let formData = new FormData();
      formData.append("foto", resizeImage);
      const res = await postPhotoAPI(formData);
      setData(res.data.data.result[0].prediction);
      setValue(1);
      toast.success("Foto berhasil di upload..");
    } catch (err) {
      // console.log("e", err, { ...err });
      setPicture(null);
      toast.error("Something Went Wrong. Please try agin!!");
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="uploadImage"
        onChange={handleLogo}
        type="file"
        multiple={false}
      />
      <div className={classes.header} />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container component={Box} marginBottom="39px">
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }}>
              <div
                style={{
                  background: "rgb(255 255 255)",
                  paddingTop: 8,
                  borderTopLeftRadius: 6,
                  borderTopRightRadius: 6,
                }}
              >
                <Typography
                  variant="h3"
                  style={{
                    textAlign: "center",
                    color: "#5f71e4",
                    borderBottom: "1px solid #e5e5e5",
                    paddingBottom: "10px",
                  }}
                >
                  KTP
                </Typography>
              </div>

              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Upload Foto" />
                <Tab label="Biodata Diri" />
              </Tabs>
              <div>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Grid
                    container
                    justify="center"
                    // className={classes.imageWrapper}
                  >
                    <Box
                      height="600px"
                      position="relative"
                      width="100%"
                      overflow="hidden"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius=".375rem"
                    >
                      <div style={{ position: "relative" }}>
                        {picture ? (
                          <img
                            src={URL.createObjectURL(picture)}
                            alt="Girl in a jacket"
                            width="500"
                            height="600"
                            style={{ borderRadius: 8 }}
                          />
                        ) : null}

                        <label htmlFor="uploadImage">
                          <Button
                            component="span"
                            variant="contained"
                            color="primary"
                            className={
                              picture ? classes.uploadButton : classes.uploadImg
                            }
                            startIcon={
                              <CameraAlt style={{ height: 20, width: 20 }} />
                            }
                          >
                            Upload Foto
                          </Button>
                        </label>
                      </div>
                    </Box>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                  style={{ padding: "16px 0" }}
                >
                  {validationData.length ? (
                    <>
                      {validationData?.map((item) => (
                        <Grid
                          key={item}
                          container
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: 12,
                          }}
                        >
                          <Grid item md={6} xs={12}>
                            <Typography variant="body2">
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={12} alignContent="flex-end">
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 600 }}
                            >
                              {item.ocr_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Box
                      height="600px"
                      position="relative"
                      width="100%"
                      overflow="hidden"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius=".375rem"
                    >
                      <Typography
                        variant="h3"
                        style={{ fontWeight: 600, color: "red" }}
                      >
                        Foto Belum Diupload!!
                      </Typography>
                    </Box>
                  )}
                </TabPanel>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        <CircularProgress color="secondary" />
      </Modal>
    </>
  );
}

export default Ktp;
