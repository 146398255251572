// core components
import Ktp from "views/admin/Ktp";
// import Dashboard from "views/admin/Dashboard";
import Bpkb from "views/admin/Bpkb";
import Sim from "views/admin/Sim";
// import Register from "views/auth/Register.js";
import Npwp from "views/admin/Npwp";
// @material-ui/icons components
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import Dns from "@material-ui/icons/Dns";
// import FlashOn from "@material-ui/icons/FlashOn";

// import Grain from "@material-ui/icons/Grain";
import {
  Person,
  RecentActors,
  CreditCard,
  CallToAction,
} from "@material-ui/icons";
// import Palette from "@material-ui/icons/Palette";

// import VpnKey from "@material-ui/icons/VpnKey";

const routes = [
  {
    path: "/ktp",
    name: "KTP",
    icon: Person,
    iconColor: "Primary",
    component: Ktp,
    layout: "/demo",
  },
  {
    path: "/bpkb",
    name: "BPKB",
    icon: RecentActors,
    iconColor: "Primary",
    component: Bpkb,
    layout: "/demo",
  },
  {
    path: "/sim",
    name: "SIM",
    icon: CreditCard,
    iconColor: "Primary",
    component: Sim,
    layout: "/demo",
  },
  {
    path: "/npwp",
    name: "NPWP",
    icon: CallToAction,
    iconColor: "Primary",
    component: Npwp,
    layout: "/demo",
  },

  // {
  //   path: "/dashboard",
  //   // name: "Login",
  //   // icon: VpnKey,
  //   // iconColor: "Info",
  //   component: Dashboard,
  //   layout: "/demo",
  // },
  //   {
  //     path: "/register",
  //     name: "Register",
  //     icon: AccountCircle,
  //     iconColor: "ErrorLight",
  //     component: Register,
  //     layout: "/auth",
  //   },
  {
    divider: true,
  },
  //   {
  //     title: "Documentation",
  //   },
  //   {
  //     href: "https://www.creative-tim.com/learning-lab/material-ui/overview/argon-dashboard?ref=admui-admin-sidebar",
  //     name: "Getting started",
  //     icon: FlashOn,
  //   },
  //   {
  //     href: "https://www.creative-tim.com/learning-lab/material-ui/colors/argon-dashboard?ref=admui-admin-sidebar",
  //     name: "Foundation",
  //     icon: Palette,
  //   },
  //   {
  //     href: "https://www.creative-tim.com/learning-lab/material-ui/alerts/argon-dashboard?ref=admui-admin-sidebar",
  //     name: "Components",
  //     icon: Dns,
  //   },
];
export default routes;
